import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of, switchMap } from 'rxjs';
import { ResponseModel } from '@app/core/models/response.model';

@Injectable()
export class HomeService {
  private URL: string = environment.apiUrl + 'home/';

  constructor(@Inject(HttpClient) private http: HttpClient) {
    // 
  }

  getDashboard(): Observable<any> {
    return this.http.get(this.URL + 'dashboard').pipe(
      switchMap((response: any) => {
        const res = response as ResponseModel<any>;
        return of(response.data);
      }),
    );
  }
}
